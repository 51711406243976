'use client';

import {
	BigNum,
	calculateMarketMaxAvailableInsurance,
	PerpMarketAccount,
	QUOTE_PRECISION_EXP,
	QUOTE_SPOT_MARKET_INDEX,
	ZERO,
} from '@drift-labs/sdk';
import { useState } from 'react';
import useDriftClient from '../../hooks/useDriftClient';
import useInterval from '../../hooks/useInterval';
import Tooltip from '../Tooltip/Tooltip';
import UIHelpTextLink from '../UIHelpTextLink';

const NoInsuranceWarningTooltip = (props: {
	marketInfo: PerpMarketAccount;
}) => {
	const driftClient = useDriftClient();

	const [availableInsurance, setAvailableInsurance] = useState(ZERO);

	useInterval(() => {
		if (!driftClient.isSubscribed) {
			setAvailableInsurance(ZERO);
			return;
		}

		const quoteAccount = driftClient.getSpotMarketAccount(
			QUOTE_SPOT_MARKET_INDEX
		);

		const newAvailableInsurance = calculateMarketMaxAvailableInsurance(
			props.marketInfo,
			quoteAccount
		);

		setAvailableInsurance(newAvailableInsurance);
	}, 1000);

	const availableInsuranceBigNum = BigNum.from(
		availableInsurance,
		QUOTE_PRECISION_EXP
	);

	return (
		<Tooltip
			allowHover
			className="text-orange-70"
			content={
				<div className="flex flex-col space-y-2">
					<span>
						This market uses a limited insurance fund (fee pool only) to protect
						against levered losses.
					</span>
					<span>
						Current fee pool : ${availableInsuranceBigNum.prettyPrint()}
					</span>
					<UIHelpTextLink
						href="https://docs.drift.trade/insurance-fund/insurance-fund-intro"
						text="Learn More"
						className="mt-2"
						popupIgnoreClickEvent
					/>
				</div>
			}
		/>
	);
};

export default NoInsuranceWarningTooltip;
