'use client';

import { MarketType } from '@drift-labs/sdk';
import Text from 'src/components/Text/Text';
import useMarketMaxLeverage from 'src/hooks/useMarketMaxLeverages';
import { twMerge } from 'tailwind-merge';
import Tooltip from '../Tooltip/Tooltip';
import useHighLeverageSpotsOpen from 'src/hooks/useHighLeverageSpotsOpen';
import { useDrawersStore } from 'src/stores/useDrawersStore';

const MarketLeverageIndicator = ({
	marketType,
	marketIndex,
	className,
}: {
	marketType: MarketType;
	marketIndex: number;
	className?: string;
}) => {
	const setDrawers = useDrawersStore((s) => s.set);
	const { highLeverageSpotsOpen, highLeverageMaxUsers } =
		useHighLeverageSpotsOpen();

	const { maxLeverage, highLeverageMaxLeverage, isHighLeverage } =
		useMarketMaxLeverage(marketType, marketIndex);

	if (!maxLeverage) {
		return null;
	}

	const chipClass = `h-full flex items-center rounded rounded-sm space-x-0.5 py-[3px] px-[4px]  bg-button-secondary-bg text-text-label opacity-50`;

	const handleShowHighLeverageDrawer = () => {
		setDrawers((s) => {
			s.marginLeverageSettings = {
				show: true,
			};
		});
	};

	const tooltipContent = isHighLeverage ? (
		<div className="flex flex-col gap-4">
			<Text.BODY2 className="leading-5">
				High leverage mode is enabled.
			</Text.BODY2>
		</div>
	) : highLeverageSpotsOpen > 0 ? (
		<div className="flex flex-col gap-4">
			<Text.BODY2 className="leading-5">
				High leverage mode is available to a limited number of Drift user
				accounts at a time. Spots remaining:{' '}
				<span className="text-warn-yellow">
					{highLeverageSpotsOpen}/{highLeverageMaxUsers}
				</span>
			</Text.BODY2>
		</div>
	) : (
		<div className="flex flex-col gap-4">
			<Text.BODY2 className="leading-5">
				High leverage mode is available to a limited number of Drift user
				accounts at a time. Spots remaining:{' '}
				<span className="text-warn-yellow">
					{highLeverageSpotsOpen}/{highLeverageMaxUsers}
				</span>
			</Text.BODY2>
			<Text.BODY2 className="leading-5">
				Please check back later for availability.
			</Text.BODY2>
		</div>
	);

	return (
		<div className="flex flex-row items-center gap-1">
			{!isHighLeverage && (
				<Text.BODY3
					className={twMerge(
						chipClass,
						!isHighLeverage &&
							'bg-chip-default-bg text-chip-default-text opacity-100',
						className
					)}
				>
					{maxLeverage}x
				</Text.BODY3>
			)}
			{!!highLeverageMaxLeverage && (
				<Tooltip content={tooltipContent} placement="bottom" allowHover>
					<Text.BODY3
						className={twMerge(
							chipClass,
							isHighLeverage
								? 'bg-interactive-secondary-bg text-interactive-link opacity-100'
								: highLeverageSpotsOpen > 0
								? 'hover:opacity-100 hover:bg-interactive-secondary-bg cursor-pointer'
								: '',
							className
						)}
						onClick={handleShowHighLeverageDrawer}
					>
						{highLeverageMaxLeverage}x
					</Text.BODY3>
				</Tooltip>
			)}
		</div>
	);
};

export default MarketLeverageIndicator;
