'use client';

import { forwardRef, useContext, useEffect } from 'react';
import { OrderbookDisplayContext } from '../../providers/orderbookDisplayProvider';
import OrderbookDisplay from './OrderbookDisplay';

const Orderbook = forwardRef(function Orderbook(_, ref) {
	const displayProps = useContext(OrderbookDisplayContext);

	useEffect(() => {
		displayProps.setIsDisplayingOrderbook(true);

		return () => {
			displayProps.setIsDisplayingOrderbook(false);
		};
	}, [displayProps.setIsDisplayingOrderbook]);

	return <OrderbookDisplay {...displayProps} ref={ref} />;
});

export default Orderbook;
