'use client';

import { CandleType } from '@drift/common';
import { twMerge } from 'tailwind-merge';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import Select from '../Inputs/Select';

const CandleTypeSelector = (props: { isMobile?: boolean }) => {
	const [currentSettings, setCurrentSettings] = useCurrentSettings();

	const currentCandleType = currentSettings?.preferredCandleType;
	const setCurrentCandleType = (newCandleType: CandleType) => {
		setCurrentSettings({
			...currentSettings,
			preferredCandleType: newCandleType,
		});
	};

	return (
		<div
			className={twMerge(
				`flex items-center`,
				props?.isMobile ? '' : 'space-x-2'
			)}
		>
			<Select.Default
				id="CandleTypeSelector"
				onChange={(newSelection) => {
					setCurrentCandleType(newSelection as CandleType);
				}}
				selection={currentCandleType}
				options={[
					{
						value: CandleType.ORACLE_PRICE,
						label: 'Candles: Oracle',
					},
					{
						value: CandleType.FILL_PRICE,
						label: 'Candles: Fills',
					},
				]}
				className={
					props.isMobile
						? 'p-2 h-7 text-text-default text-xs mr-1.5 min-w-[135px]'
						: ''
				}
			/>
		</div>
	);
};

export default CandleTypeSelector;
