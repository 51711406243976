import React from 'react';
import { twMerge } from 'tailwind-merge';
import Text from '../Text/Text';
import {
	ContractType,
	MarketType,
	OracleSource,
	PerpMarketAccount,
	PerpMarketConfig,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import { ENUM_UTILS } from '@drift/common';
import dayjs from 'dayjs';
import {
	MarketCategory,
	PERP_MARKET_CATEGORIES_CONFIG,
	SPL_PERP_MARKETS,
	SPOT_MARKET_CATEGORIES_CONFIG,
} from 'src/constants/markets';

const NEW_AND_TRENDING_WEEKS = 3;

export const perpMarketCategoryFilter = (
	market: PerpMarketConfig,
	marketCategory: MarketCategory,
	isHot: boolean,
	perpMarketAccount: PerpMarketAccount
) => {
	const isPredictionMarket = ENUM_UTILS.match(
		perpMarketAccount.contractType,
		ContractType.PREDICTION
	);

	if (isPredictionMarket) return false; // don't show prediction markets for now

	if (marketCategory === MarketCategory.All) {
		return true;
	}

	if (marketCategory === MarketCategory.NewAndTrending) {
		return (
			dayjs(market.launchTs)
				.add(NEW_AND_TRENDING_WEEKS, 'week')
				.isAfter(dayjs()) || isHot
		);
	}

	if (marketCategory === MarketCategory.PreLaunch) {
		if (!perpMarketAccount) return false;

		return ENUM_UTILS.match(
			perpMarketAccount.amm.oracleSource,
			OracleSource.Prelaunch
		);
	}

	if (marketCategory === MarketCategory.Solana) {
		return SPL_PERP_MARKETS.includes(market.marketIndex);
	}

	if (marketCategory === MarketCategory.L1And2) {
		return market.category.includes('L1') || market.category.includes('L2');
	}

	if (marketCategory === MarketCategory.Meme) {
		return market.category.includes('Meme');
	}

	return false;
};

export const spotMarketCategoryFilter = (
	market: SpotMarketConfig,
	marketCategory: MarketCategory
) => {
	if (marketCategory === MarketCategory.All) {
		return true;
	}

	if (marketCategory === MarketCategory.NewAndTrending) {
		return dayjs(market.launchTs)
			.add(NEW_AND_TRENDING_WEEKS, 'week')
			.isAfter(dayjs());
	}

	return false;
};

const MarketCategoryTab = ({
	onClick,
	label,
	selected,
}: {
	onClick: () => void;
	label: React.ReactNode;
	selected: boolean;
}) => {
	return (
		<Text.BODY2
			className={twMerge(
				'px-2 py-1 cursor-pointer hover:bg-container-bg-hover rounded-sm text-text-label whitespace-nowrap',
				selected &&
					'bg-tab-selected-bg hover:bg-tab-selected-bg text-text-emphasis'
			)}
			onClick={onClick}
		>
			{label}
		</Text.BODY2>
	);
};

export const MarketCategoriesTab = ({
	marketType,
	selectedCategory,
	setSelectedCategory,
}: {
	marketType: MarketType;
	selectedCategory: MarketCategory;
	setSelectedCategory: (category: MarketCategory) => void;
}) => {
	const configToUse = ENUM_UTILS.match(marketType, MarketType.PERP)
		? PERP_MARKET_CATEGORIES_CONFIG
		: SPOT_MARKET_CATEGORIES_CONFIG;

	return configToUse.length === 1 ? null : ( // don't show tabs if there's only one category
		<div className="flex items-center max-w-full gap-1 pb-1 overflow-x-auto">
			{configToUse.map((category) => (
				<MarketCategoryTab
					key={category.value}
					onClick={() => setSelectedCategory(category.value)}
					label={category.label}
					selected={category.value === selectedCategory}
				/>
			))}
		</div>
	);
};
