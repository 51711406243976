'use client';

import {
	MouseEvent as ReactMouseEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Menu } from '@drift-labs/icons';
import useTradeTableSizePreference from 'src/hooks/tradePageTablePreference/useTradeTableSizePreference';
import { TRADE_PAGE_TABLE_DEFAULT_SIZE } from 'src/hooks/tradePageTablePreference/tradePageTablePreferenceTypes';

const MAX_SIZE = 480;

const DraggableTableResizer = () => {
	const [tradePageTableSize, setTradePageTableSize] =
		useTradeTableSizePreference();
	const [isResizing, setIsResizing] = useState(false);

	const tableStartSize = useRef<number>(0);
	const mouseYStart = useRef<number>(0);
	const mouseYChange = useRef<number>(0);

	const handleResizeEnd = () => {
		setIsResizing(false);
		mouseYStart.current = 0;
		mouseYChange.current = 0;
		window.removeEventListener('mouseup', handleResizeEnd);
		window.removeEventListener('mousemove', handleResizeMove);
	};

	const handleResizeStart = (event: ReactMouseEvent) => {
		if (!isResizing) {
			setIsResizing(true);
			tableStartSize.current =
				tradePageTableSize === 'undefined'
					? parseInt(TRADE_PAGE_TABLE_DEFAULT_SIZE)
					: parseInt(tradePageTableSize);
			mouseYStart.current = event.pageY;
			mouseYChange.current = 0;
			window.addEventListener('mouseup', handleResizeEnd);
		}
	};

	const handleResizeMove = (event: MouseEvent) => {
		if (isResizing) {
			mouseYChange.current = mouseYStart.current - event.clientY;

			const newSize = Math.max(
				0,
				Math.min(MAX_SIZE, tableStartSize.current + mouseYChange.current)
			);

			setTradePageTableSize(`${newSize}`);
		}
	};

	useEffect(() => {
		if (isResizing) {
			window.addEventListener('mousemove', handleResizeMove);
		}

		return () => window.removeEventListener('mousemove', handleResizeMove);
	}, [isResizing]);

	return (
		<div className="relative w-full h-0">
			<div
				className="w-full h-[8px] absolute top-[-8px] hover:bg-button-secondary-bg-hover flex flex-row items-center justify-center cursor-ns-resize select-none"
				onMouseDown={handleResizeStart}
			>
				<Menu className="opacity-50" color={'var(--text-label)'} />
			</div>
			{/* div to cover TV chart while resizing to prevent it from blocking window mousemove event */}
			<div
				className="w-full h-[3000px] absolute top-[-1504px] cursor-ns-resize"
				style={{ display: isResizing ? 'block' : 'none' }}
			></div>
		</div>
	);
};

export default DraggableTableResizer;
