'use client';

import { memo } from 'react';
import { useLocalStorageStringState } from '../hooks/useLocalStorageState';
import Orderbook from './Orderbook/Orderbook';
import RecentMarketTrades from './RecentMarketTrades';
import UnderlinedTabs from './TabbedNavigation/UnderlinedTabs';

const RecentMarketTabsMemo = memo(function RecentMarketTabs({
	activeTab,
	setActiveTab,
}: {
	activeTab: string;
	setActiveTab: (newTab: string) => void;
}) {
	return (
		<>
			<UnderlinedTabs
				onChange={(selection) => {
					setActiveTab(selection);
				}}
				currentSelection={activeTab}
				options={[
					{
						value: 'orderbook',
						label: 'Orderbook',
					},
					{
						value: 'recentTrades',
						label: `Recent Trades`,
					},
				]}
				tabClassName="grow justify-center leading-[16px] flex-1"
				underlineClassName="h-[1px]"
				bgHighlightSelected
			/>
			{activeTab === 'recentTrades' ? <RecentMarketTrades /> : <Orderbook />}
		</>
	);
});

const OrderbookAndRecentTrades = memo(function PublicTrades() {
	const [currentTab, setCurrentTab] = useLocalStorageStringState(
		'orderbookDisplayPreference',
		'orderbook'
	);

	return (
		<div className="flex flex-col grow">
			<RecentMarketTabsMemo
				activeTab={currentTab}
				setActiveTab={setCurrentTab}
			/>
		</div>
	);
});

export default OrderbookAndRecentTrades;
