'use client';

import BaseGrid from 'src/components/Grids/BaseGrid/BaseGrid';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { useWindowSizeStore } from 'src/stores/useWindowSizeStore';
import { JSX } from 'react';
type CellDef = {
	col: number;
	row: number;
	colSpan: number;
	rowSpan: number;
};

type WidthCellDefMatch = {
	breakPoint: number;
	maxWidth?: number;
	skip?: boolean;
	cellDef: CellDef;
};
[];

const getCellDef = (
	col: number,
	colSpan: number,
	row: number,
	rowSpan: number
) => ({
	col,
	colSpan,
	row,
	rowSpan,
});

const getCellDefWidthMatch = (
	breakPoint: number,
	col: number,
	colSpan: number,
	row: number,
	rowSpan: number,
	maxWidth?: number,
	skip?: boolean
): WidthCellDefMatch => ({
	breakPoint,
	maxWidth,
	skip,
	cellDef: getCellDef(col, colSpan, row, rowSpan),
});

const CellFromDef = (
	props: PropsWithChildren<{
		cellDef: CellDef;
		className?: string;
		skipWrapper?: boolean;
	}>
) => (
	<BaseGrid.Cell
		style={{
			gridRowStart: props.cellDef.row,
			gridRowEnd: props.cellDef.row + props.cellDef.rowSpan,
			gridColumnStart: props.cellDef.col,
			gridColumnEnd: props.cellDef.col + props.cellDef.colSpan,
		}}
		className={props.className}
		skipWrapper={props.skipWrapper}
	>
		{props.children}
	</BaseGrid.Cell>
);

export type ResizableGridProps = {
	breakpointsStyleDefinitions: {
		breakPoint: number;
		columnDefs: string;
		rowDefs: string;
	}[];
	cells: {
		Element: JSX.Element;
		cellBreakpoints: Parameters<typeof getCellDefWidthMatch>[];
		key?: string;
		skipWrapper?: boolean;
		className?: string;
		renderCondition?: () => boolean;
	}[];
	className?: string;
	style?: CSSProperties;
};

const LayoutGrid = (props: ResizableGridProps) => {
	const windowWidth = useWindowSizeStore((s) => s.realtimeWidth);

	if (!windowWidth) return null;

	const breakPoints = props.breakpointsStyleDefinitions.sort(
		(A, B) => B.breakPoint - A.breakPoint
	);

	const currentBreakPointIndex = breakPoints.findIndex(
		(breakPointDef) => breakPointDef.breakPoint <= windowWidth
	);

	return (
		<BaseGrid
			className={props.className}
			columns={breakPoints[currentBreakPointIndex].columnDefs}
			rows={breakPoints[currentBreakPointIndex].rowDefs}
		>
			{props.cells.map((Cell, index) => {
				const shouldRender = Cell.renderCondition
					? Cell.renderCondition()
					: true;

				if (!shouldRender) return null;

				const breakpoint = Cell.cellBreakpoints
					.sort((A, B) => B[0] - A[0])
					.find((cell) => cell[0] <= windowWidth);

				if (breakpoint && !breakpoint[6]) {
					return (
						<CellFromDef
							cellDef={getCellDef(
								breakpoint[1],
								breakpoint[2],
								breakpoint[3],
								breakpoint[4]
							)}
							skipWrapper={Cell.skipWrapper}
							className={Cell.className}
							key={index}
						>
							{Cell.Element}
						</CellFromDef>
					);
				}

				return null;
			})}
		</BaseGrid>
	);
};

export default LayoutGrid;
