'use client';

import { useEffect, useState } from 'react';
import useAppEventEmitter from '../hooks/useAppEventEmitter';
import { MobileTopDrawerSelection } from '../utils/DriftAppEvents';
import XButton from './Buttons/XButton';
import { LiquidationAlertTable } from './Liquidations/LiquidationAlertChip';
import Text from './Text/Text';

const MobileTopDrawer = () => {
	const [showDrawer, setShowDrawer] =
		useState<MobileTopDrawerSelection>(undefined);

	const appEventEmitter = useAppEventEmitter();

	useEffect(() => {
		const handler: (typeof appEventEmitter)[' _emitType']['showMobileInfoDrawer'] =
			(drawerType) => {
				if (drawerType === showDrawer) {
					setShowDrawer(undefined);
				} else {
					setShowDrawer(drawerType);
				}
			};

		appEventEmitter.on('showMobileInfoDrawer', handler);

		return () => {
			appEventEmitter.removeListener('showMobileInfoDrawer', handler);
		};
	}, [appEventEmitter, showDrawer]);

	const drawerTitle = (() => {
		switch (showDrawer) {
			case 'accountHealth':
				return `Account(s) at risk`;
			default:
				return '';
		}
	})();

	if (!showDrawer) return null;

	return (
		<div className="flex-col w-full px-4 py-4 mb-4 overflow-auto bg-container-bg text-text-label shrink-0">
			{/* Drawer heading */}
			<div className="flex justify-between w-full pb-4 mb-4 border-b text-text-default border-container-border">
				<div className="">
					<Text.H3>{drawerTitle}</Text.H3>
				</div>
				<XButton
					stroke="var(--text-interactive)"
					onClick={() => {
						setShowDrawer(undefined);
					}}
				/>
			</div>

			{/* Drawer Content */}
			{(() => {
				switch (showDrawer) {
					case 'accountHealth':
						return <LiquidationAlertTable />;
				}
			})()}
		</div>
	);
};

export default MobileTopDrawer;
