'use client';

import FundingRateInfo from 'src/components/PerpPageStats/FundingRateInfo';
import MarketBar from 'src/components/MarketBar/MarketBar';
import { MarketSelectorDisplay } from 'src/components/MarketSelector';
// import RecentMarketTrades from 'src/components/RecentMarketTrades';
// import TradeForm from 'src/components/TradeForm/TradeForm';
import TradePageTable from 'src/components/TradePageTable/TradePageTable';
import React, { useEffect, useRef, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import MobileTopDrawer from '../MobileTopDrawer';
import MobileChartAndOrderbook from '../MobileChartAndOrderbook';
import { useWindowSizeStore } from 'src/stores/useWindowSizeStore';
import Button from '../Button';
import { matchEnum } from '@drift/common';
import { MarketType } from '@drift-labs/sdk';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import { usePathname } from 'next/navigation';
import useSafePush from 'src/hooks/useSafePush';
import { Wallet } from '@drift-labs/icons';
import useDepositAndTradeShouldBeEnabled from 'src/components/TradeForm/useDepositAndTradeShouldBeEnabled';

const MobileTradeButtons = () => {
	const connected = useWalletIsConnected();
	const set = useDriftStore((s) => s.set);
	const selectedMarketType = useDriftStore(
		(s) => s.selectedMarket.current.marketType
	);
	const pathname = usePathname();
	const safePush = useSafePush();

	const isInDepositAndTradeState = useDepositAndTradeShouldBeEnabled();

	const handleConnectWallet = () => {
		set((s) => {
			s.wallet.lastRouteBeforeConnect = pathname;
		});
		safePush('/onboarding/connect');
		return;
	};

	const handleOpenTradeFormBuy = () => {
		set((s) => {
			s.modals.showTradeFormModal = true;
			s.tradeForm.side = 'buy';
		});
	};

	const handleOpenTradeFormSell = () => {
		set((s) => {
			s.modals.showTradeFormModal = true;
			s.tradeForm.side = 'sell';
		});
	};

	const LongShortButtons = () => {
		return (
			<>
				<Button.BigSemantic
					className="p-2"
					positive
					onClick={handleOpenTradeFormBuy}
				>
					{matchEnum(selectedMarketType, MarketType.PERP) ? 'Long' : 'Buy'}
				</Button.BigSemantic>
				<Button.BigSemantic className="p-2" onClick={handleOpenTradeFormSell}>
					{matchEnum(selectedMarketType, MarketType.PERP) ? 'Short' : 'Sell'}
				</Button.BigSemantic>
			</>
		);
	};

	return connected || isInDepositAndTradeState ? (
		<LongShortButtons />
	) : (
		<Button.Secondary size="LARGE" onClick={handleConnectWallet} highlight>
			<>
				<Wallet className="mr-1" size={20} />
				<span className="mt-0.5"> Connect Wallet </span>
			</>
		</Button.Secondary>
	);
};

const MobileTradePage = () => {
	const marketBarRef = useRef<HTMLDivElement>(null);
	const fundingRateBarRef = useRef<HTMLDivElement>(null);
	const tradeButtonsRef = useRef<HTMLDivElement>(null);
	const scrollAreaRef = useRef<HTMLDivElement>(null);
	const windowHeight = useWindowSizeStore((s) => s.realtimeHeight);
	const [tableMinHeight, setTableMinHeight] = useState(0);
	const [scrollAreaPadding, setScrollAreaPadding] = useState(0);

	useEffect(() => {
		const marketBarHeight = marketBarRef.current?.clientHeight ?? 0;
		const fundingRateBarHeight = fundingRateBarRef.current?.clientHeight ?? 0;
		const headerHeight = document.querySelector('.header')?.clientHeight ?? 0;
		const tradeButtonsHeight = tradeButtonsRef.current?.clientHeight ?? 0;

		setScrollAreaPadding(tradeButtonsHeight);

		setTableMinHeight(
			windowHeight -
				marketBarHeight -
				fundingRateBarHeight -
				headerHeight -
				tradeButtonsHeight
		);
	}, [windowHeight, fundingRateBarRef.current, marketBarRef.current]);

	return (
		<>
			<MobileTopDrawer />

			<div className="subheader bg-container-bg">
				<MarketBar />
			</div>

			<div
				className="z-50 p-0 border-b rounded-md funding-rate-container bg-container-bg border-container-border md:p-2"
				ref={marketBarRef}
			>
				<MarketSelectorDisplay />
			</div>

			<div className="z-40" ref={fundingRateBarRef}>
				<FundingRateInfo mobile />
			</div>

			<main
				className="main hidden-scroll"
				ref={scrollAreaRef}
				style={{ paddingBottom: `${scrollAreaPadding}px` }}
			>
				<div className="main-overview">
					<div className="buyandsellcontaniner">
						<div className="topcontainer">
							<MobileChartAndOrderbook />
							<div className="bg-container-bg md:rounded-md"></div>
						</div>
					</div>

					<div
						className="block sm:hidden bg-container-bg"
						style={{ minHeight: `${tableMinHeight}px` }}
					>
						<TradePageTable />
					</div>
				</div>
			</main>

			<div
				className="fixed bottom-0 z-20 flex flex-row w-full p-3 pb-6 space-x-3 border-t space-between backdrop-blur-md border-container-border"
				ref={tradeButtonsRef}
			>
				<MobileTradeButtons />
			</div>
		</>
	);
};

export default MobileTradePage;
