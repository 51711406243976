'use client';

import { OrderActionExplanation, PositionDirection } from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	Opaque,
	Serializer,
	UIMarket,
	UISerializableOrderActionRecord,
	matchEnum,
} from '@drift/common';
import { PublicKey } from '@solana/web3.js';
import {
	memo,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariables';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useDriftTheme from 'src/hooks/useDriftTheme';
import { OrderMatcherContext } from 'src/providers/orderRecordMatcherProvider';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useDevSwitchIsOn from '../hooks/useDevSwitchIsOn';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useMarketsInfoStore from '../stores/useMarketsInfoStore';
import UI_UTILS from '../utils/uiUtils';
import RecentTradesDevTooltip from './DevTooltip/RecentTradesDevTooltip';
import InlineLoadingBar from './InlineLoadingBar/InlineLoadingBar';
import Text from './Text/Text';
import Tooltip from './Tooltip/Tooltip';
import Counterparty from './Counterparty';
import ReactDOM from 'react-dom';
import useDevStore from '../stores/useDevStore';
import CopyButton from './Utils/CopyButton';
import Utility from './Inputs/Utility';
import { Close } from '@drift-labs/icons';
import { useMarketStepSize } from 'src/hooks/useMarketStepSize';
import { twMerge } from 'tailwind-merge';

interface RecentTradeRowProps {
	fill: UISerializableOrderActionRecord;
	highlightTrade: boolean;
	isPos: boolean;
	copyAndSnapToTrade: (fill: UISerializableOrderActionRecord) => void;
	copyTrade: (fill: UISerializableOrderActionRecord) => void;
	lightTheme: boolean;
	baseDecimals: number;
	quoteDecimals: number;
	devSwitch?: boolean;
	setShowTradeInfo: (trade?: UISerializableOrderActionRecord) => void;
}

type RecordKey = Opaque<string, 'RecordKey'>;

const getRecordKey = (record: UISerializableOrderActionRecord): RecordKey => {
	return `${record?.fillRecordId?.toString() ?? ''}_${
		record?.takerOrderId?.toString() ?? ''
	}_${record?.makerOrderId?.toString() ?? ''}` as RecordKey;
};

const RecentTradeRowMemo = memo(function RecentTradeRow({
	fill,
	highlightTrade,
	isPos,
	copyAndSnapToTrade,
	copyTrade,
	lightTheme,
	devSwitch,
	baseDecimals,
	quoteDecimals,
	setShowTradeInfo,
}: RecentTradeRowProps) {
	const isLightTheme = lightTheme;

	const entryPrice = COMMON_UI_UTILS.calculateAverageEntryPrice(
		fill.quoteAssetAmountFilled,
		fill.baseAssetAmountFilled
	);

	const isLiq = ENUM_UTILS.match(
		fill.actionExplanation,
		OrderActionExplanation.LIQUIDATION
	);

	const [toggleIsOn, setToggleIsOn] = useState(false);

	const shownTradeInfo = useDevStore((s) => s.showInfoForTrade);
	const setDevStore = useDevStore((s) => s.set);

	const togglePauseUpdates = (orderKey: RecordKey) => {
		setDevStore((s) => {
			const currentState = s.pausedRecentTradeKeys ?? {};
			if (currentState[orderKey]) {
				delete currentState[orderKey];
			} else {
				currentState[orderKey] = true;
			}
		});
	};

	return (
		<div
			className={`relative leading-7 grid grid-cols-3 font-numeral px-2 py-1 text-xs text-text-secondary hover:cursor-pointer  ${
				highlightTrade
					? `${
							isLightTheme
								? 'bg-container-bg-hover hover:bg-container-bg-hover'
								: 'bg-button-secondary-bg hover:bg-button-secondary-bg-hover'
					  }`
					: 'hover:bg-container-bg-hover'
			}`}
			onClick={() => {
				highlightTrade ? copyAndSnapToTrade(fill) : copyTrade(fill);
			}}
		>
			<Text.BODY2
				className={`inline-flex ${
					isPos ? `text-positive-green` : `text-negative-red`
				}`}
			>
				{isLiq && (
					<Tooltip
						content={
							<span>{`${UI_UTILS.abbreviateAddress(
								fill.taker
							)} was liquidated by ${UI_UTILS.abbreviateAddress(
								fill.maker
							)}`}</span>
						}
					>
						<div className="mr-0.5">💀</div>
					</Tooltip>
				)}
				{entryPrice.toNum().toLocaleString(undefined, {
					maximumFractionDigits: quoteDecimals,
					minimumFractionDigits: quoteDecimals,
				})}
			</Text.BODY2>
			<Text.BODY2 className={`text-right mr-5`}>
				{UI_UTILS.toFixedLocaleString(
					fill.baseAssetAmountFilled.toNum(),
					baseDecimals
				)}
			</Text.BODY2>
			<div className="inline-flex justify-end space-x-1">
				<Text.BODY2 className={`text-right`}>
					{fill.ts &&
						new Date(fill.ts.toNumber() * 1000).toLocaleTimeString('en-US', {
							hourCycle: 'h23',
						})}
				</Text.BODY2>
				<Counterparty
					// TODO : possible to create some kind of recent cache for stringified authorities? To save on toStrings here
					counterparty={fill.maker?.toString()}
					marketType={fill.marketType}
					actionExplanation={fill.actionExplanation}
					isGenericTrade
				/>
			</div>

			{devSwitch && (
				<>
					<span className="absolute -right-1.5">
						<RecentTradesDevTooltip
							isOn={
								toggleIsOn ||
								(shownTradeInfo &&
									getRecordKey(shownTradeInfo) === getRecordKey(fill))
							}
							onToggle={() => {
								setToggleIsOn(!toggleIsOn);
								togglePauseUpdates(getRecordKey(fill));
							}}
							setShowContent={(value) => {
								setShowTradeInfo(value || toggleIsOn ? fill : undefined);
							}}
						/>
					</span>
				</>
			)}
		</div>
	);
});

const RecentTradesHeaderMemo = memo(function RecentTradesHeader({
	marketName,
	isPredictionMarket,
}: {
	marketName: string;
	isPredictionMarket: boolean;
}) {
	return (
		<div className="grid items-center grid-cols-3 py-1 pl-2 pr-2 text-xs text-center recent-trades-header place-content-center text-text-tertiary font-display bg-container-bg">
			<Text.BODY3 className="text-left">Price (USD) </Text.BODY3>
			<Text.BODY3 className="pr-1 mr-5 text-right">
				Size ({isPredictionMarket ? 'Shares' : marketName})
			</Text.BODY3>
			<Text.BODY3 className="text-right mr-7">Time</Text.BODY3>
		</div>
	);
});

const RecentMarketTrades = memo(function RecentTradeMemo(props: {
	className?: string;
}) {
	const orderMatcher = useContext(OrderMatcherContext);
	const devSwitch = useDevSwitchIsOn();
	const theme = useDriftTheme();
	const isLightTheme = matchEnum(theme, DriftTheme.light);
	const driftClientIsReady = useDriftClientIsReady();

	const [updatesPaused, setUpdatesPaused] = useState(false);
	const pausedRecentTrades = useDevStore((s) => s.pausedRecentTradeKeys);

	useEffect(() => {
		const shouldBePaused = Object.keys(pausedRecentTrades ?? {}).length > 0;
		setUpdatesPaused(shouldBePaused);
	}, [pausedRecentTrades]);

	const fills = useDriftStore((s) => s.marketTradeHistory.trades);
	const fillsToRenderRef = useRef<UISerializableOrderActionRecord[]>(fills);
	useEffect(() => {
		if (!updatesPaused) {
			fillsToRenderRef.current = fills;
		}
	}, [fills, updatesPaused]);

	const selectedMarketBaseAssetSymbol = useDriftStore((s) =>
		s.selectedMarket.current.baseAssetSymbol()
	);
	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const isLoading = useDriftStore(
		(s) => s.loadingElements.recentTrades?.isLoading
	);
	const setState = useDriftStore((s) => s.set);

	const user: PublicKey = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.pubKey
	);

	const marketsInfoStore = useMarketsInfoStore();

	// Quote decimals displayed should be number of decimals in min tick size (same as display decimals I believe)
	const quoteDecimals = driftClientIsReady
		? marketsInfoStore?.getMarketInfoByIndexAndType(
				selectedMarketId.marketIndex,
				selectedMarketId.marketType
		  )?.genericInfo?.priceDisplayDecimals ?? 2
		: 2;

	// Base decimals displayed should be number of decimals in min step size
	const stepSize = useMarketStepSize(selectedMarketId);
	const baseDecimals = (`${stepSize}`.split('.')[1] ?? '').length;

	const copyTrade = useCallback(
		(fillInfo: UISerializableOrderActionRecord) => {
			setState((s) => {
				const direction = fillInfo.baseAssetAmountFilled.ltZero()
					? 'sell'
					: 'buy';
				s.tradeForm.side = direction;
				s.tradeForm.leadSide = 'base';
				// @ts-expect-error
				s.tradeForm.baseSizeStringValue =
					fillInfo.baseAssetAmountFilled.printShort();
			});

			setState((s) => {
				if (s.tradeForm.orderType !== 'market') {
					const avgPrice = COMMON_UI_UTILS.calculateAverageEntryPrice(
						fillInfo.quoteAssetAmountFilled,
						fillInfo.baseAssetAmountFilled
					).toTradePrecision();

					// @ts-expect-error
					s.tradeForm.priceBoxStringValue = avgPrice;
				}
			});
		},
		[setState]
	);

	const copyAndSnapToTrade = useCallback(
		(fillInfo: UISerializableOrderActionRecord) => {
			copyTrade(fillInfo);
			setState((s) => {
				s.userInfoTable.currentTab = 'tradeHistory';
				s.userInfoTable.tradeHistoryMarketFilter = new UIMarket(
					fillInfo.marketIndex,
					fillInfo.marketType
				).symbol;
			});
		},
		[copyTrade, setState]
	);

	const tradeToShow = useDevStore((s) => s.showInfoForTrade);
	const setDevStore = useDevStore((s) => s.set);
	const setShowTradeInfo = (trade?: UISerializableOrderActionRecord) => {
		setDevStore((s) => {
			s.showInfoForTrade = trade;
		});
	};

	const fillsToRender = fillsToRenderRef.current;

	return (
		<>
			<RecentTradesHeaderMemo
				marketName={selectedMarketBaseAssetSymbol}
				isPredictionMarket={selectedMarket.isPredictionMarket}
			/>

			{isLoading ? (
				<div className="flex items-center justify-center w-full h-full">
					<InlineLoadingBar />
				</div>
			) : (
				fillsToRender &&
				!!fillsToRender.length && (
					<div
						className={twMerge(
							`bg-container-bg overflow-x-auto overflow-y-scroll thin-scroll xs:min-h-[25vh] sm:min-h-0 flex-grow sm:h-0`,
							props.className
						)}
					>
						{fillsToRender.map((fill, index) => {
							const highlightTrade =
								!!user &&
								(fill.maker?.equals(user) || fill.taker?.equals(user));

							const isPos = matchEnum(
								fill.takerOrderDirection,
								PositionDirection.LONG
							);

							return (
								<RecentTradeRowMemo
									// if there is somehow a race condition where dupe records make it here, add index to make sure
									// component doesn't get stuck with duplicate key error and it should cleanup near instantly
									key={`${orderMatcher?.getOrderUniquenessKey(fill)}_${index}`}
									fill={fill}
									highlightTrade={highlightTrade}
									isPos={isPos}
									copyAndSnapToTrade={copyAndSnapToTrade}
									copyTrade={copyTrade}
									lightTheme={isLightTheme}
									devSwitch={devSwitch}
									quoteDecimals={quoteDecimals}
									baseDecimals={baseDecimals}
									setShowTradeInfo={setShowTradeInfo}
								/>
							);
						})}
					</div>
				)
			)}

			{tradeToShow &&
				ReactDOM.createPortal(
					<div
						style={{
							zIndex: 100,
							position: 'absolute',
							top: 0,
							left: 0,
						}}
					>
						<div className="relative p-4 text-white break-normal bg-darkBlue-90">
							<span
								onClick={() => {
									setShowTradeInfo(undefined);
								}}
								className="absolute top-0 right-0 w-6 h-6"
							>
								<Close size={24} />
							</span>
							<span>
								<span className="flex flex-col">
									<div className="grid grid-cols-[auto_minmax(0,_1fr)] space-y-1">
										<span className="mr-4">Base Amount Filled : </span>
										<span>{tradeToShow.baseAssetAmountFilled.print()}</span>
										<span className="mr-4">Quote Amount Filled : </span>
										<span>{tradeToShow.quoteAssetAmountFilled.print()}</span>
										<span className="mr-4">Price : </span>
										<span>
											{COMMON_UI_UTILS.calculateAverageEntryPrice(
												tradeToShow.quoteAssetAmountFilled,
												tradeToShow.baseAssetAmountFilled
											).print()}
										</span>
									</div>
									<Utility.VERTSPACERXL />
									<div className="grid grid-cols-[auto_minmax(0,_1fr)]">
										{Object.entries(
											Serializer.Serialize.UIOrderActionRecord(tradeToShow)
										).map(([key, value]) => {
											return (
												<>
													<span className="mr-4">{key}</span>
													<div className="flex justify-between w-full space-x-2 font-mono">
														<span className="break-all max-w-[500px]">
															{/* @ts-ignore */}
															{value}
														</span>
														<CopyButton
															toastMessage={`Copied ${value}`}
															copyValue={value as string}
														/>
													</div>
												</>
											);
										})}
									</div>
								</span>
							</span>
						</div>
					</div>,
					document.body
				)}
		</>
	);
});

export default RecentMarketTrades;
