'use client';

import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

const FavouriteButton = ({
	highlighted,
	onClick,
	className,
	wrapperSizePx = 24,
	starSizePx = 16,
}: {
	highlighted: boolean;
	onClick: React.MouseEventHandler<HTMLDivElement>;
	className?: string;
	wrapperSizePx?: number;
	starSizePx?: number;
}) => {
	const [toggleAnimation, setToggleAnimation] = useState('');

	return (
		<div
			className={`flex items-center justify-center`}
			style={{ width: wrapperSizePx, height: wrapperSizePx }}
			onClick={(e) => {
				onClick(e);
				if (!highlighted) {
					setToggleAnimation('is_animating');
				}
			}}
		>
			<div
				onAnimationEnd={() => setToggleAnimation('')}
				className={twMerge(
					`hover:cursor-pointer star ${toggleAnimation}`,
					highlighted && 'star-faved',
					className
				)}
				style={{
					width: starSizePx,
					height: starSizePx,
				}}
			/>
		</div>
	);
};

export default FavouriteButton;
