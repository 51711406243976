import styled from '@emotion/styled';
import GridSectionWrapper from 'src/components/GridSections/GridSectionWrapper/GridSectionWrapper';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

const GridLayout = styled.div<Pick<GridProps, 'columns' | 'rows'>>`
	display: grid;
	grid-template-columns: ${(props) => props.columns};
	grid-template-rows: ${(props) => props.rows};
`;

export type GridProps = PropsWithChildren<{
	className?: string;
	columns: string;
	rows: string;
}>;

const BaseGrid = (props: GridProps) => {
	return (
		<GridLayout {...props} className={twMerge('gap-2', props.className)}>
			{props.children}
		</GridLayout>
	);
};

const Cell = (
	props: PropsWithChildren<{
		className?: string;
		style?: CSSProperties;
		skipWrapper?: boolean;
	}>
) => {
	return props.skipWrapper ? (
		<div style={props.style} className={props.className}>
			{props.children}
		</div>
	) : (
		<GridSectionWrapper
			style={props.style}
			className={`overflow-auto thin-scroll ${props.className}`}
		>
			{props.children}
		</GridSectionWrapper>
	);
};

BaseGrid.Cell = Cell;
export default BaseGrid;
