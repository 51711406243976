import { Info } from '@drift-labs/icons';

const RecentTradesDevTooltip = (props: {
	isOn: boolean;
	onToggle: () => void;
	setShowContent: (value: boolean) => void;
}) => {
	const handleMouseEnter = () => {
		props.setShowContent(true);
	};

	const handleMouseLeave = () => {
		props.setShowContent(false);
	};

	const handleClick = () => {
		props.onToggle();
	};

	return (
		<>
			<span
				onClick={handleClick}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<Info height={18} width={18} color={props.isOn ? 'red' : undefined} />
			</span>
		</>
	);
};

export default RecentTradesDevTooltip;
