'use client';

import { ContractType, MarketType } from '@drift-labs/sdk';
import { COMMON_UTILS, ENUM_UTILS, MarketId } from '@drift/common';
import { useMemo } from 'react';
import Env, {
	CurrentPerpMarkets,
	CurrentSpotMarkets,
} from '../environmentVariables/EnvironmentVariables';
import use24hMarketDataFromStore from './use24hMarketDataFromStore';
import useFavouriteMarkets from './useFavouriteMarkets';
import UI_UTILS from '../utils/uiUtils';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';

const TWO_WEEKS_MS = 1000 * 60 * 60 * 24 * 14;

export type MarketOrderingInfo = {
	marketIndex: number;
	marketType: MarketType;
	volume: number;
	marketCap: number;
	isNew: boolean;
	isFavourited: boolean;
	isHot: boolean;
	baseAssetSymbol: string;
	favouriteIndex: number;
	isSol?: boolean;
	isDrift?: boolean;
};

/**
 * Markets Ordering:
 *
 * The markets in the bar should be ordered by the following attributes, in order:
 *
 * - isFavourited
 * - isSol
 * - isNew
 * - isHot (not implemented for now, need to determine what "hot" means)
 * - 24hVolume
 * - marketIndex
 * @param orderingInfo
 * @returns
 */
const getOrderingScoresForMarket = (orderingInfo: MarketOrderingInfo) => {
	return [
		orderingInfo.isFavourited ? 2 + orderingInfo.favouriteIndex : 0,
		orderingInfo.isSol ? 1 : 0,
		orderingInfo.isDrift ? 1 : 0,
		orderingInfo.isNew ? 1 : 0,
		orderingInfo.isHot ? 1 : 0,
		orderingInfo.marketCap ?? 0,
		orderingInfo.marketIndex * -1, // Lower market indexes are "higher" in ordering
	];
};

const useOrderedMarkets = (
	marketType: MarketType,
	includePredictionMarkets?: boolean
): MarketOrderingInfo[] => {
	const marketsData24H = use24hMarketDataFromStore();
	const { getFavouriteMarketIndex, favoriteMarkets } = useFavouriteMarkets();
	const driftClient = useDriftClient();
	const isDriftClientReady = useDriftClientIsReady();

	/**
	 * Calculate whether markets are hot by comparing their daily volume z scores to the average z score for the market
	 */
	const hotMarketData: { [marketKey: string]: boolean } = useMemo(() => {
		if (marketsData24H.length === 0) return {};

		const idAndZScoreAndRawVol = marketsData24H.map((m) => {
			return [
				new MarketId(m.marketIndex, m.marketType),
				m.dailyVolumeIncreaseZScore,
				m.quoteVolume,
			] as [MarketId, number, number];
		});

		const medianZScore = COMMON_UTILS.MATH.NUM.median(
			idAndZScoreAndRawVol.map((a) => a[1])
		);

		return idAndZScoreAndRawVol.reduce(
			(previous, [currentMarketId, currentMarketZScore, currentVol]) => {
				return {
					...previous,
					[currentMarketId.key]: UI_UTILS.marketIsHot(
						currentMarketZScore,
						medianZScore,
						currentVol
					),
				};
			},
			{} as { [marketKey: string]: boolean }
		);
	}, [marketsData24H]);

	const marketsDetails: {
		marketIndex: number;
		marketType: MarketType;
		baseAssetSymbol: string;
		launchTs: number;
	}[] = useMemo(
		() =>
			ENUM_UTILS.match(marketType, MarketType.PERP)
				? CurrentPerpMarkets.map((mkt) => ({
						marketIndex: mkt.marketIndex,
						marketType: MarketType.PERP,
						baseAssetSymbol: mkt.baseAssetSymbol,
						launchTs: mkt.launchTs,
				  }))
				: CurrentSpotMarkets.map((mkt) => ({
						marketIndex: mkt.marketIndex,
						marketType: MarketType.SPOT,
						baseAssetSymbol: mkt.symbol,
						launchTs: mkt?.launchTs ?? 0, // No launchTs on spot markets for some reason
				  })).filter((mkt) => mkt.marketIndex !== 0), // Filter out USDC
		[marketType]
	);

	const marketOrderingInfo = useMemo(() => {
		if (!driftClient || !isDriftClientReady) return [];

		const markets = marketsDetails
			.map((mktInfo) => {
				const marketId = new MarketId(mktInfo.marketIndex, mktInfo.marketType);

				const marketData = marketsData24H.find(
					(marketData) =>
						marketData.marketIndex === mktInfo.marketIndex &&
						ENUM_UTILS.match(mktInfo.marketType, marketData.marketType)
				);

				const favouriteIndex = getFavouriteMarketIndex(marketId);

				return {
					marketIndex: mktInfo.marketIndex,
					marketType: mktInfo.marketType,
					baseAssetSymbol: mktInfo.baseAssetSymbol,
					volume: marketData?.quoteVolume ?? 0,
					isNew: Date.now() - mktInfo.launchTs < TWO_WEEKS_MS, // Is new if market less than 2 weeks old
					isFavourited: favouriteIndex !== -1,
					isHot: hotMarketData[marketId.key] ?? false,
					marketCap: marketData?.marketCap,
					favouriteIndex: favoriteMarkets.length - favouriteIndex,
					isSol: mktInfo.baseAssetSymbol === 'SOL',
					isDrift: mktInfo.baseAssetSymbol === 'DRIFT',
				};
			})
			.filter((market) => {
				if (ENUM_UTILS.match(marketType, MarketType.SPOT)) return true;

				if (includePredictionMarkets) return true;

				if (Env.showPredictionMarketsInPerpTradePage) return true;

				const perpMarketAccount = driftClient.getPerpMarketAccount(
					market.marketIndex
				);

				if (!perpMarketAccount) return true;

				return !ENUM_UTILS.match(
					perpMarketAccount.contractType,
					ContractType.PREDICTION
				);
			});

		return [...markets].sort((mktA, mktB) => {
			// Passing [B, A] into the method because we want to sort by descending rank.
			return COMMON_UTILS.getTieredSortScore(
				getOrderingScoresForMarket(mktB),
				getOrderingScoresForMarket(mktA)
			);
		});
	}, [
		marketsData24H,
		getFavouriteMarketIndex,
		marketsDetails,
		hotMarketData,
		favoriteMarkets,
		driftClient,
		isDriftClientReady,
	]);

	return marketOrderingInfo;
};

export default useOrderedMarkets;
