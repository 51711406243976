'use client';

import { twMerge } from 'tailwind-merge';
import Text from '../Text/Text';
import { ENUM_UTILS, UIMarket } from '@drift/common';
import {
	BigNum,
	PerpMarketConfig,
	SpotMarketConfig,
	PerpMarketAccount,
	MarketType,
} from '@drift-labs/sdk';
import MarketIcon from '../Utils/MarketIcon';
import MarketLeverageIndicator from '../Utils/MarketLeverageIndicator';
import NoInsuranceWarningTooltip from '../Utils/NoInsuranceWarningTooltip';
import FavouriteButton from '../Buttons/FavouriteButton';
import useFavouriteMarkets from 'src/hooks/useFavouriteMarkets';
import NumberDisplayV2 from '../Utils/NumberDisplayV2';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import NumberDisplay from '../Utils/NumberDisplay';
import NumLib from 'src/utils/NumLib';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import SortIcon from '../Icons/SortIcon';
import Tooltip from '../Tooltip/Tooltip';
import PoweredByOpenBookAndPhoenix from '../Utils/PoweredByOpenbookAndPhoenix';
import { MarketCategory } from 'src/constants/markets';

import FuelBoostTooltip from '../Tooltip/FuelBoostTooltip';

const GRID_COMMON_CLASSES_PRO = 'grid gap-2 sm:gap-3';
const GRID_COMMON_CLASSES_LITE = 'grid gap-2 sm:gap-9';
const PERP_GRID_TEMPLATE_COLS_CLASS_PRO =
	'grid-cols-[minmax(190px,2fr)_minmax(70px,1fr)_minmax(70px,1fr)] sm:grid-cols-[210px_70px_70px_74px_93px_108px]';
const PERP_GRID_TEMPLATE_COLS_CLASS_LITE =
	'grid-cols-[minmax(190px,2fr)_minmax(70px,1fr)_minmax(70px,1fr)] sm:grid-cols-[210px_84px_70px_70px]';
const SPOT_GRID_TEMPLATE_COLS_CLASS_PRO =
	'grid-cols-[minmax(190px,2fr)_minmax(94px,1fr)_minmax(60px,1fr)] sm:grid-cols-[210px_76px_76px_74px_93px_90px]';
const SPOT_GRID_TEMPLATE_COLS_CLASS_LITE =
	'grid-cols-[minmax(190px,2fr)_minmax(94px,1fr)_minmax(60px,1fr)] sm:grid-cols-[210px_84px_70px_70px]';

const HEADERS_CONTAINER_HEIGHT = 40;

export enum MarketColumnHeader {
	Market,
	Price,
	PriceChangePct,
	Volume,
	OpenInterest,
	Funding,
	PriceLow24h,
	PriceHigh24h,
}

type HeaderConfig = {
	label: string;
	value: MarketColumnHeader;
	breakpoint: 'mobile-only' | 'desktop-only' | 'all';
	uiMode: 'all' | 'pro-only';
	showForMarketTypes: 'all' | 'perp-only' | 'spot-only';
};

const PERP_MARKETS_COLUMN_HEADERS_CONFIG: HeaderConfig[] = [
	{
		label: 'Market',
		value: MarketColumnHeader.Market,
		breakpoint: 'all',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: 'Price',
		value: MarketColumnHeader.Price,
		breakpoint: 'all',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: '24h %',
		value: MarketColumnHeader.PriceChangePct,
		breakpoint: 'all',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: '24h Vol',
		value: MarketColumnHeader.Volume,
		breakpoint: 'desktop-only',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: 'Open Interest',
		value: MarketColumnHeader.OpenInterest,
		breakpoint: 'desktop-only',
		uiMode: 'pro-only',
		showForMarketTypes: 'perp-only',
	},
	{
		label: 'Funding',
		value: MarketColumnHeader.Funding,
		breakpoint: 'desktop-only',
		uiMode: 'pro-only',
		showForMarketTypes: 'perp-only',
	},
	{
		label: '24h Low',
		value: MarketColumnHeader.PriceLow24h,
		breakpoint: 'desktop-only',
		uiMode: 'pro-only',
		showForMarketTypes: 'spot-only',
	},
	{
		label: '24h High',
		value: MarketColumnHeader.PriceHigh24h,
		breakpoint: 'desktop-only',
		uiMode: 'pro-only',
		showForMarketTypes: 'spot-only',
	},
];

const SPOT_MARKETS_COLUMN_HEADERS_CONFIG: HeaderConfig[] = [
	{
		label: 'Market',
		value: MarketColumnHeader.Market,
		breakpoint: 'all',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: 'Price',
		value: MarketColumnHeader.Price,
		breakpoint: 'all',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: '24h %',
		value: MarketColumnHeader.PriceChangePct,
		breakpoint: 'all',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: '24h Vol',
		value: MarketColumnHeader.Volume,
		breakpoint: 'desktop-only',
		uiMode: 'all',
		showForMarketTypes: 'all',
	},
	{
		label: '24h Low',
		value: MarketColumnHeader.PriceLow24h,
		breakpoint: 'desktop-only',
		uiMode: 'pro-only',
		showForMarketTypes: 'spot-only',
	},
	{
		label: '24h High',
		value: MarketColumnHeader.PriceHigh24h,
		breakpoint: 'desktop-only',
		uiMode: 'pro-only',
		showForMarketTypes: 'spot-only',
	},
];

export type MarketDisplayData = {
	symbol: string;
	baseAssetSymbol: string;
	market: UIMarket;
	price: BigNum;
	pricePercentChange: number;
	price24hAgo: number;
	volume: number;
	high: number;
	low: number;
	config: PerpMarketConfig | SpotMarketConfig;
	noInsuranceFund: boolean;
	launchTs?: number;
	openInterest?: number;
	funding?: number;
	funding24H?: number;
	marketAccount?: PerpMarketAccount;
	isHot?: boolean;
	isFavorite: boolean;
	fuelBoostMaker?: number;
	fuelBoostTaker?: number;
	fuelBoostPosition?: number;
};

const useGetDisplayStatus = (
	currentMarketIsPerp: boolean,
	marketIsPerp: boolean
) => {
	const isLiteMode = useIsLiteMode();
	const isMobile = useIsMobileScreenSize();

	const getDisplayStatus = (marketHeader: MarketColumnHeader) => {
		const config = marketIsPerp
			? PERP_MARKETS_COLUMN_HEADERS_CONFIG
			: SPOT_MARKETS_COLUMN_HEADERS_CONFIG;

		const headerConfig = config.find((header) => header.value === marketHeader);

		if (!headerConfig) {
			return false;
		}

		const satisfiesBreakpoint =
			headerConfig.breakpoint === 'all' ||
			(isMobile
				? 'mobile-only' === headerConfig.breakpoint
				: 'desktop-only' === headerConfig.breakpoint);
		const satisfiesUiMode =
			headerConfig.uiMode === 'all' ||
			(headerConfig.uiMode === 'pro-only' && !isLiteMode);
		const satisfiesCurrentMarketPage =
			headerConfig.showForMarketTypes === 'all' ||
			(currentMarketIsPerp
				? headerConfig.showForMarketTypes === 'perp-only'
				: headerConfig.showForMarketTypes === 'spot-only');

		return satisfiesBreakpoint && satisfiesUiMode && satisfiesCurrentMarketPage;
	};

	return getDisplayStatus;
};

const getGridClasses = (isPerp: boolean, isLiteMode: boolean) => {
	const commonClasses = isLiteMode
		? GRID_COMMON_CLASSES_LITE
		: GRID_COMMON_CLASSES_PRO;
	const gridTemplateColsClass = isPerp
		? isLiteMode
			? PERP_GRID_TEMPLATE_COLS_CLASS_LITE
			: PERP_GRID_TEMPLATE_COLS_CLASS_PRO
		: isLiteMode
		? SPOT_GRID_TEMPLATE_COLS_CLASS_LITE
		: SPOT_GRID_TEMPLATE_COLS_CLASS_PRO;

	return twMerge(commonClasses, gridTemplateColsClass);
};

const MarketCell = ({ value }: { value?: React.ReactNode }) => {
	return (
		<Text.BODY2 className="flex items-center justify-end text-right">
			{value ? (
				value
			) : (
				<SkeletonValuePlaceholder className="w-16 h-[18px]" loading={true} />
			)}
		</Text.BODY2>
	);
};

const MarketRow = ({
	currentMarketIsPerp,
	market,
	fundingMultiplier,
	fundingRateUnit,
	selected,
	toggleFavourite,
	onSelect,
	marketCategory,
}: {
	currentMarketIsPerp: boolean;
	market: MarketDisplayData;
	fundingMultiplier: number;
	fundingRateUnit: string;
	selected: boolean;
	toggleFavourite: (market: UIMarket) => void;
	onSelect: () => void;
	marketCategory: MarketCategory;
}) => {
	const getMarketInfoByIndexAndType = useMarketsInfoStore(
		(s) => s.getMarketInfoByIndexAndType
	);
	const getDisplayStatus = useGetDisplayStatus(
		marketCategory === MarketCategory.Spot ? false : currentMarketIsPerp,
		market.market.isPerp
	);
	const isLiteMode = useIsLiteMode();
	const isMobile = useIsMobileScreenSize();

	const decimals =
		getMarketInfoByIndexAndType(
			market.config.marketIndex,
			market.market.marketType
		)?.genericInfo?.tickSizeExponent ?? 2;

	const fundingRateString =
		!isNaN(market.funding) && isFinite(market.funding)
			? NumLib.formatNum.toTradePrecisionString(
					market.funding24H * fundingMultiplier
			  )
			: null;

	const lowPrice24h =
		!isNaN(market.low) && isFinite(market.low)
			? NumLib.formatNum.toTradePrecisionString(market.low ?? 0)
			: null;
	const highPrice24h =
		!isNaN(market.high) && isFinite(market.high)
			? NumLib.formatNum.toTradePrecisionString(market.high ?? 0)
			: null;

	return (
		<div
			className={twMerge(
				getGridClasses(currentMarketIsPerp, isLiteMode),
				'py-4 px-2 text-text-default cursor-pointer hover:bg-container-border max-w-full border-b border-container-border last:border-b-0',
				selected && 'bg-container-border'
			)}
			onClick={onSelect}
		>
			{/** Market */}
			{getDisplayStatus(MarketColumnHeader.Market) && (
				<div className="flex flex-col">
					<div className="flex items-center gap-1">
						<FavouriteButton
							highlighted={market.isFavorite}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								toggleFavourite(market.market);
							}}
							starSizePx={16}
							wrapperSizePx={16}
						/>

						<MarketIcon
							marketSymbol={market.market.market.symbol}
							sizeClass="w-[18px] h-[18px]"
							isSpot={market.market.isSpot}
						/>

						<Text.BODY2 className="font-semibold text-text-emphasis">
							{isMobile && !currentMarketIsPerp
								? market.market.marketName.replace('/USDC', '')
								: market.market.marketName}
						</Text.BODY2>

						<MarketLeverageIndicator
							marketType={market.market.marketType}
							marketIndex={market.market.market.marketIndex}
							className="text-[11px] leading-[12px] pt-[3px] px-1 h-auto"
						/>

						{market.fuelBoostMaker > 1 ||
						market.fuelBoostTaker > 1 ||
						market.fuelBoostPosition > 1 ? (
							<div className="flex flex-row items-center ml-1">
								<FuelBoostTooltip
									fuelBoostMaker={market.fuelBoostMaker}
									fuelBoostTaker={market.fuelBoostTaker}
									fuelBoostPosition={market.fuelBoostPosition}
								/>
							</div>
						) : null}

						{market.market.isPerp &&
							market.noInsuranceFund &&
							market.marketAccount && (
								<NoInsuranceWarningTooltip marketInfo={market.marketAccount} />
							)}

						{market.market.isSpot && market.symbol.includes('DRIFT') && (
							<Tooltip
								allowHover
								className="text-interactive-link"
								content={<PoweredByOpenBookAndPhoenix />}
							/>
						)}
					</div>

					{getDisplayStatus(MarketColumnHeader.Volume) ? null : !isNaN(
							market.volume
					  ) ? (
						<Text.BODY2 className="mt-1 sm:hidden text-text-label ml-[42px]">
							{`$${NumLib.millify(market.volume).displayString}`}
						</Text.BODY2>
					) : (
						<SkeletonValuePlaceholder className="w-16 h-[18px]" loading />
					)}
				</div>
			)}

			{/** Price */}
			{getDisplayStatus(MarketColumnHeader.Price) && (
				<MarketCell
					value={
						market.price ? (
							<NumberDisplayV2
								value={market.price}
								displayType="notional"
								subType="$"
								toFixed={decimals}
							/>
						) : null
					}
				/>
			)}

			{/** 24h % Change */}
			{getDisplayStatus(MarketColumnHeader.PriceChangePct) && (
				<MarketCell
					value={
						!isNaN(market.pricePercentChange) ? (
							<NumberDisplay
								value={market.pricePercentChange}
								displayType="percentageChange"
								colourCoded
							/>
						) : null
					}
				/>
			)}

			{/** 24h Vol */}
			{getDisplayStatus(MarketColumnHeader.Volume) && (
				<MarketCell
					value={
						!isNaN(market.volume)
							? `$${NumLib.millify(market.volume).displayString}`
							: null
					}
				/>
			)}

			{/** Open Interest */}
			{getDisplayStatus(MarketColumnHeader.OpenInterest) && (
				<MarketCell
					value={
						!isNaN(market.openInterest)
							? `$${NumLib.millify(market.openInterest).displayString}`
							: null
					}
				/>
			)}

			{/** Funding */}
			{getDisplayStatus(MarketColumnHeader.Funding) && (
				<MarketCell
					value={
						fundingRateString
							? `${fundingRateString}%${fundingRateUnit === 'y' ? ' APR' : ''}`
							: null
					}
				/>
			)}

			{/** 24h Low */}
			{getDisplayStatus(MarketColumnHeader.PriceLow24h) && (
				<MarketCell value={lowPrice24h ? `$${lowPrice24h}` : null} />
			)}

			{/** 24h High */}
			{getDisplayStatus(MarketColumnHeader.PriceHigh24h) && (
				<MarketCell value={highPrice24h ? `$${highPrice24h}` : null} />
			)}
		</div>
	);
};

export const MarketList = ({
	markets,
	marketType,
	onClose,
	toggleSort,
	sort,
	filtersElementHeight,
	marketCategory,
}: {
	markets: MarketDisplayData[];
	marketType: MarketType;
	onClose: () => void;
	toggleSort: (header: MarketColumnHeader) => void;
	sort?: { header: MarketColumnHeader; isDescending: boolean };
	filtersElementHeight: number;
	marketCategory: MarketCategory;
}) => {
	const currentMarketIsPerp = ENUM_UTILS.match(marketType, MarketType.PERP);

	const actions = useDriftActions();
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const getDisplayStatus = useGetDisplayStatus(
		marketCategory === MarketCategory.Spot ? false : currentMarketIsPerp,
		currentMarketIsPerp
	);
	const isLiteMode = useIsLiteMode();
	const isMobile = useIsMobileScreenSize();

	const { toggleFavourite } = useFavouriteMarkets();

	const [fundingRateUnit, setFundingRateUnit] = useLocalStorageState(
		'fundingRateUnitPreference'
	);
	const fundingMultiplier = fundingRateUnit === 'y' ? 24 * 365.25 : 1;

	const headersConfig = currentMarketIsPerp
		? PERP_MARKETS_COLUMN_HEADERS_CONFIG
		: SPOT_MARKETS_COLUMN_HEADERS_CONFIG;

	const topMarkets = currentMarketIsPerp
		? markets.filter((market) => market.market.isPerp)
		: markets.filter((market) => market.market.isSpot);
	const bottomMarkets = currentMarketIsPerp
		? markets.filter((market) => market.market.isSpot)
		: markets.filter((market) => market.market.isPerp);

	const onSelectMarket = (uiMarket: UIMarket) => () => {
		actions.switchMarket({
			marketIndex: uiMarket.marketIndex,
			marketType: uiMarket.marketType,
		});
		onClose();
	};

	const toggleFundingRateUnit = () => {
		setFundingRateUnit(fundingRateUnit === 'h' ? 'y' : 'h');
	};

	return (
		<div className="flex flex-col">
			{/** Header */}
			<div
				className={twMerge(
					getGridClasses(currentMarketIsPerp, isLiteMode),
					'px-2 py-3 sticky bg-container-bg z-30'
				)}
				style={{
					top: filtersElementHeight, // nearest scrolling ancestor is the entire market selector popup
				}}
			>
				{headersConfig.map((header) => {
					const display = getDisplayStatus(header.value);

					const isMarketHeaderInMobile =
						isMobile && header.value === MarketColumnHeader.Market;

					const handleSort = () => {
						if (isMarketHeaderInMobile) {
							toggleSort(MarketColumnHeader.Volume);
							return;
						}

						toggleSort(header.value);
					};

					return !display ? null : (
						<div
							key={header.value}
							className={twMerge(
								'flex items-center cursor-pointer justify-end gap-[2px] select-none',
								header.value === MarketColumnHeader.Market
									? 'text-left justify-start'
									: 'text-right'
							)}
						>
							{header.value === MarketColumnHeader.Funding && (
								<Text.MICRO1
									className="text-text-secondary mr-[2px]"
									onClick={toggleFundingRateUnit}
								>
									({fundingRateUnit === 'h' ? '1h' : 'APR'})
								</Text.MICRO1>
							)}
							<Text.MICRO1 onClick={handleSort} className="text-text-default">
								{isMarketHeaderInMobile ? 'Market/24h Vol.' : header.label}
							</Text.MICRO1>
							<SortIcon
								onClick={handleSort}
								state={
									sort?.header !== header.value
										? 'neutral'
										: sort.isDescending
										? 'descending'
										: 'ascending'
								}
								size={16}
							/>
						</div>
					);
				})}
			</div>

			{/** Market List */}
			<div className="flex flex-col">
				<div className="relative flex flex-col">
					{topMarkets.length > 0 && (
						<div
							className="sticky z-20 px-2 py-1 border-y bg-container-bg border-container-border"
							style={{ top: filtersElementHeight + HEADERS_CONTAINER_HEIGHT }}
						>
							<Text.MICRO1 className="text-text-label">
								{currentMarketIsPerp ? 'Perp' : 'Spot'} Markets
							</Text.MICRO1>
						</div>
					)}
					{topMarkets.map((market) => (
						<MarketRow
							onSelect={onSelectMarket(market.market)}
							key={market.symbol}
							currentMarketIsPerp={currentMarketIsPerp}
							market={market}
							fundingMultiplier={fundingMultiplier}
							fundingRateUnit={fundingRateUnit}
							toggleFavourite={toggleFavourite}
							selected={selectedMarket.equals(market.market)}
							marketCategory={marketCategory}
						/>
					))}
				</div>

				<div className="relative flex flex-col">
					{bottomMarkets.length > 0 && (
						<div
							className="sticky z-20 px-2 py-1 border-y bg-container-bg border-container-border"
							style={{ top: filtersElementHeight + HEADERS_CONTAINER_HEIGHT }}
						>
							<Text.MICRO1 className="text-text-label">
								{currentMarketIsPerp ? 'Spot' : 'Perp'} Markets
							</Text.MICRO1>
						</div>
					)}
					{bottomMarkets.map((market) => (
						<MarketRow
							onSelect={onSelectMarket(market.market)}
							key={market.symbol}
							currentMarketIsPerp={currentMarketIsPerp}
							market={market}
							fundingMultiplier={fundingMultiplier}
							fundingRateUnit={fundingRateUnit}
							toggleFavourite={toggleFavourite}
							selected={selectedMarket.equals(market.market)}
							marketCategory={marketCategory}
						/>
					))}
				</div>

				{markets.length === 0 && (
					<Text.BODY2 className="flex items-center justify-center w-full py-4 grow text-text-disabled">
						No Data
					</Text.BODY2>
				)}
			</div>
		</div>
	);
};
