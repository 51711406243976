'use client';

import { MarketType } from '@drift-labs/sdk';
import { COMMON_UI_UTILS, MarketId, UIMarket } from '@drift/common';
import { useCallback, useMemo } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { OrderedPerpMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useLocalStorageState from './useLocalStorageState';

const DEFAULT_STATE = OrderedPerpMarkets.slice(0, 3).map((mkt) =>
	COMMON_UI_UTILS.getMarketKey(mkt.marketIndex, MarketType.PERP)
);

const useFavouriteMarkets = () => {
	const [favoriteMarkets, setFavoriteMarkets] = useLocalStorageState<string[]>(
		'favoriteMarkets',
		DEFAULT_STATE
	);

	const addFavourite = useCallback(
		(newMarket: UIMarket) => {
			setFavoriteMarkets([
				...favoriteMarkets,
				COMMON_UI_UTILS.getMarketKey(
					newMarket.market.marketIndex,
					newMarket.marketType
				),
			]);
		},
		[favoriteMarkets]
	);

	const removeFavourite = useCallback(
		(marketToRemove: UIMarket) => {
			setFavoriteMarkets(
				favoriteMarkets.filter(
					(marketKey) =>
						marketKey !==
						COMMON_UI_UTILS.getMarketKey(
							marketToRemove.market.marketIndex,
							marketToRemove.marketType
						)
				)
			);
		},
		[favoriteMarkets]
	);

	const toggleFavourite = useCallback(
		(market: UIMarket) => {
			if (
				favoriteMarkets.includes(
					COMMON_UI_UTILS.getMarketKey(
						market.market.marketIndex,
						market.marketType
					)
				)
			)
				removeFavourite(market);
			else addFavourite(market);
		},
		[favoriteMarkets]
	);

	const getFavouriteMarketIndex = useCallback(
		(market: MarketId) => {
			return favoriteMarkets.findIndex((mkt) => mkt === market.key);
		},
		[favoriteMarkets]
	);

	const favouriteMarketsIncludes = useCallback(
		(market: MarketId) => {
			return getFavouriteMarketIndex(market) !== -1;
		},
		[favoriteMarkets]
	);

	const result = useMemo(() => {
		return {
			favoriteMarkets,
			addFavourite,
			removeFavourite,
			toggleFavourite,
			favouriteMarketsIncludes,
			getFavouriteMarketIndex,
		};
	}, [favoriteMarkets, addFavourite, removeFavourite, toggleFavourite]);

	return result;
};

export default singletonHook(
	{
		favoriteMarkets: [],
		addFavourite: (_newMarket: UIMarket) => {},
		removeFavourite: (_marketToRemove: UIMarket) => {},
		toggleFavourite: (_market: UIMarket) => {},
		favouriteMarketsIncludes: (_market: MarketId) => false,
		getFavouriteMarketIndex: (_market: MarketId) => -1,
	},
	useFavouriteMarkets
);
