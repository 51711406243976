'use client';

import React, { useContext, useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
// import MobileChartAndOrderbook from '../MobileChartAndOrderbook';
import { useTargetMarketSlotStaleness } from 'src/hooks/useTargetMarketSlotStaleness';
import { SlotContext } from 'src/providers/currentSlotProvider';
import useNotificationStore from 'src/stores/useNotificationStore';
import { notify } from 'src/utils/notifications';
import Button from '../components/Button';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DriftWindow } from 'src/window/driftWindow';

const NOTIFICATION_DEBOUNCE_TIME_MS = 2_000;
const MARKET_SLOT_STALENESS_THRESHOLD = 100;
const SLOT_CONTEXT_STALENESS_THRESHOLD = -100;
const INITIAL_LOAD_GRACE_PERIOD_MS = 5000;
const STALE_NOTIFICATION_ID = 'stale-data-warning';

const showWarningSubject = new BehaviorSubject<boolean>(false);

export const useDisplayStalePageWarning = () => {
	const currentMarket = useDriftStore((s) => s.selectedMarket.current);
	const { removeNotificationById } = useNotificationStore();
	const currentSlotContext = useContext(SlotContext);

	const mountTimeRef = React.useRef(Date.now());

	const marketSlotStaleness = useTargetMarketSlotStaleness(
		currentMarket.marketId
	);

	const isSlotContextStale =
		marketSlotStaleness < SLOT_CONTEXT_STALENESS_THRESHOLD &&
		mountTimeRef.current + INITIAL_LOAD_GRACE_PERIOD_MS < Date.now();

	const marketSlotIsStale =
		marketSlotStaleness > MARKET_SLOT_STALENESS_THRESHOLD ||
		DriftWindow.FORCE_DISPLAY_STALE_PAGE_WARNING;
	const isAfterGracePeriod =
		mountTimeRef.current + INITIAL_LOAD_GRACE_PERIOD_MS < Date.now();

	const showWarningNotification = () => {
		notify({
			id: STALE_NOTIFICATION_ID,
			type: 'warning',
			message: 'Page data may be stale.',
			description:
				"Please refresh the page to ensure you're seeing the most up-to-date data.",
			bottomContent: (
				<Button.Secondary
					size="SMALL"
					onClick={() => {
						window.location.reload();
					}}
				>
					Refresh Page
				</Button.Secondary>
			),
			showUntilCancelled: true,
		});
	};

	useEffect(() => {
		if (isSlotContextStale) {
			currentSlotContext.subscribe();
		}
	}, [isSlotContextStale]);

	useEffect(() => {
		const subscription = showWarningSubject
			.pipe(debounceTime(NOTIFICATION_DEBOUNCE_TIME_MS))
			.subscribe((shouldShowWarning) => {
				if (shouldShowWarning) {
					showWarningNotification();
				} else {
					removeNotificationById(STALE_NOTIFICATION_ID);
				}
			});

		return () => {
			subscription.unsubscribe();
		};
	}, [showWarningSubject]);

	useEffect(() => {
		showWarningSubject.next(marketSlotIsStale && isAfterGracePeriod);
	}, [marketSlotIsStale, isAfterGracePeriod]);
};
