'use client';

import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import Text from './Text/Text';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import Button from './Button';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

export const NetAccountValueDisplayLite = () => {
	const actions = useDriftActions();
	const currentAccountKey = useDriftAccountStore((s) => s.currentUserKey);
	const set = useDriftStore((s) => s.set);
	const connected = useWalletIsConnected();

	const marginInfo = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.marginInfo
	);

	const totalValue = marginInfo
		? BigNum.from(marginInfo.netUsdValue, QUOTE_PRECISION_EXP)
		: BigNum.zero(QUOTE_PRECISION_EXP);

	const showPopup = () => {
		set((s) => {
			s.modalTargetAccountKey = currentAccountKey ?? '';
			s.modalCollateralType = 0;
		});
		actions.showDepositModal(0, currentAccountKey, true);
	};

	if (!connected) {
		return null;
	}

	return (
		<div className="flex flex-row items-center justify-between">
			<div className="flex flex-col w-[50%]">
				<div className="text-text-label">
					<Text.MICRO1>Net Account Value</Text.MICRO1>
				</div>
				<div className="text-text-default leading-4">
					<Text.BODY1>{totalValue.toNotional()}</Text.BODY1>
				</div>
			</div>
			<div>
				<Button.Secondary
					size="SMALL"
					onClick={showPopup}
					className="w-24 h-8" //bg-container-bg hover:bg-container-bg-selected text-positive-green border-positive-green border"
					positiveGreen={totalValue.eqZero()}
				>
					Deposit
				</Button.Secondary>
			</div>
		</div>
	);
};
